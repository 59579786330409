import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import NftItem from "./NftItem";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

// Styled

//From Template

export const StyledButton = styled.button`
  padding: 5px 10px;
  border-radius: 7px;
  border: none;
  background: var(--gradient-yellow);
 
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 120px;
  transform: scale(1.3);
  cursor: pointer;
  transition: all ease-in-out 400ms;
  :hover{
    transform: scale(1.4);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 800px) {
    flex-direction: row;
    width: 60%;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const Unstake = () => {
    const dispatch = useDispatch();
    // let callGetBalance = false;
    const showTokens = false;
    // let nftBalance = 0;
    const [nftBalance, setNftBalance] = useState(0);
    const [nftIds, setNftIds] = useState([]);
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [stakingNft, setStakingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Select NFT you want to unstake`);
    const [tokenId, setTokenId] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    // Get NFT balance From Wallet
    const getBalance = () => {
        let internalNftBalance = 0;

        setFeedback(`Getting your NFT balance`);
        setStakingNft(true);

        // Getting balance of wallet
        blockchain.smartContractStake.methods
            .balanceOf(blockchain.account)
            .call({
                from: blockchain.account
            })
            .then((receipt) => {
                console.log(receipt);

                setNftBalance(receipt);
                internalNftBalance = receipt;
                setFeedback(
                    `Showing your NFT Staked Balance`
                );
            })
            .then((receipt) => {
                setFeedback(
                    `Getting your staked NFT Ids`
                );
                console.log("nftBalance = " + internalNftBalance);

                // Getting each of the IDs of the NFTs that user holds
                for (let i = 0; i < internalNftBalance; i++) {
                    blockchain.smartContractStake.methods
                        .tokenOfOwnerByIndex(blockchain.account, i)
                        .call({
                            from: blockchain.account
                        })
                        .then((receipt) => {
                            console.log(`ID ${i} ====> ` + receipt);

                            setNftIds(nftIds => [...nftIds, receipt]);
                        });
                }
                // console.log('ist of ids ' + listOfIds);
                // setNftIds(nftIds => [...nftIds, listOfIds[0]]);
                console.log('nftIds ' + nftIds);

                setFeedback(`Showing your NFTs`);

                setStakingNft(false);
                dispatch(fetchData(blockchain.account));
            })
    }

    // const getNftIds = () => {
    //     setFeedback(`Getting NFT Ids`);
    //     setStakingNft(true);


    // }

    // TEST FOR STAKING //
    const unstakeNFTs = () => {
        let cost = 0;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * 0);
        let totalGasLimit = String(gasLimit * 1);

        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);

        setFeedback(`UNSTAKING your ${CONFIG.NFT_NAME}...`);
        setStakingNft(true);
        blockchain.smartContractStake.methods
            .unstake(tokenId)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again. You might have rejected the transaction.");
                setStakingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `The ${CONFIG.NFT_NAME} is now Untaked! Enjoy the profits.`
                );
                setStakingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    // #####################

    const decrementTokenId = () => {
        let TokenId = tokenId - 1;
        if (TokenId < 1) {
            TokenId = 1;
        }
        setTokenId(TokenId);
    };

    const incrementTokenId = () => {
        let newTokenId = tokenId + 1;
        if (newTokenId > 7) {
            newTokenId = 7;
        }
        setTokenId(newTokenId);
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContractStake !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    //To show tokens
    const showtokens = () => {
        if (blockchain.account !== "" && blockchain.smartContractStake !== null) {
            true;
        }
        else {
            false
        }
    };

    // To select NFT to unstake
    const handleNftSelection = event => {
        setTokenId(event.target.value);

        console.log('NFT selected:', event.target.value);
    };

    // To call getBalance when connected
    // useEffect(() => {
    //     if (callGetBalance)  getBalance()
    //     return () => { callGetBalance = false; }
    //     },[]);


    return (

        <ResponsiveWrapper flex={1} style={{ height: "auto", padding: 10 }} test>

            <s.Container
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{
                    padding: 10,
                    borderRadius: 24,
                    border: "4px var(--secondary)",
                }}
            >


                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (<>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}>
                        The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>{CONFIG.MARKETPLACE}</StyledLink></>
                ) : (<>


                    <s.SpacerXSmall />

                    <s.SpacerSmall />
                    {blockchain.account === "" ||
                        blockchain.smartContractStake === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                            {/* <s.TextDescription
                                style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                    width: "60%"
                                }}>
                                NETWORK: {CONFIG.NETWORK.NAME}
                            </s.TextDescription> */}
                            <s.SpacerSmall />
                            <StyledButton id="connect_btn" onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                            }}>
                                CONNECT
                            </StyledButton>
                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <s.SpacerSmall />
                                    <s.TextDescription
                                        style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                        }}
                                    >
                                        {blockchain.errorMsg}
                                    </s.TextDescription>
                                </>
                            ) : null}

                        </s.Container>
                    ) : (
                        <>
                            <s.TextDescription
                                style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                }}
                            >
                                {feedback}
                            </s.TextDescription>

                            <s.SpacerLarge />

                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                    disabled={stakingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getBalance();
                                        getData();
                                    }}
                                >
                                    {stakingNft ? "BUSY" : "SHOW MY STAKED NFTs"}
                                </StyledButton>
                            </s.Container>

                            <s.SpacerMedium />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>

                                <s.SpacerMedium />
                                <s.TextDescription
                                    style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                    }}
                                >
                                    Selected Token Id: {tokenId}
                                    You may unstake 1 NFT at a time

                                </s.TextDescription>
                                <s.SpacerMedium />

                            </s.Container>

                            {/* Component to Show NFTs Balance */}
                            {/* Esto tiene que ser un componente */}

                            {showtokens ?
                                <div>
                                    <div style={{ color: "white", fontSize: "3em" }}>{nftBalance}</div>
                                    <ul className="nft_holdings_container" style={{ background: "white", height: "200px", width: "200px" }}>
                                        {
                                            nftIds.map(function (item, i) {
                                                return <NftItem func={handleNftSelection} nftImg={`https://ipfs.io/ipfs/QmVwmpoUS5MaYwsUJt9dGYHWeu9nVDf2gC9pY4SUXZjL1D/${item}.png`} val={item} key={i}></NftItem>

                                                //    <input key={i}/> + <img className="nft_holdings" src={`https://ipfs.io/ipfs/QmVwmpoUS5MaYwsUJt9dGYHWeu9nVDf2gC9pY4SUXZjL1D/${item}.png`}></img>
                                            })
                                        }
                                    </ul>
                                </div>

                                : <div style={{ color: "white", fontSize: "3em" }}>Nothing to show</div>}

                            <s.SpacerSmall />

                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                    disabled={stakingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        unstakeNFTs();
                                        getData();
                                    }}
                                >
                                    {stakingNft ? "BUSY" : "UNSTAKE"}
                                </StyledButton>
                            </s.Container>



                        </>
                    )}

                </>
                )}
                <s.SpacerMedium />

                {/* <s.TextDescription
                    style={{
                        textAlign: "center",
                        color: "var(--primary-text)",
                    }}
                >
                    <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                        {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                    </StyledLink>
                </s.TextDescription> */}

            </s.Container>
            <s.SpacerLarge />

        </ResponsiveWrapper>


    )
}

export default Unstake;