import React from 'react';
import cx from 'classnames';


const About = ({ open = false, isToggled, onToggle }) => {

    const aboutCollapse = cx('about2', {
        'open' : open
    });

    return (
        <div className='about_wrapper'>

            <h2 className='about_title' id='about'> About Us</h2>


            <div className="about_container">
                {/* <div className="about_img">
                    <img src="/config/images/about-img.png" alt="about img" />
                </div> */}

                <div className="about_text">
                    <p className='about2'>The Wolves of DOGE Street (WODS) is a community first and foremost.
                        Borrowing inspiration from some of the most iconic
                        NFT projects of our time, the WODS pays homage to what
                        has come before while setting out to be one of the most innovative
                        and compelling NFT opportunities for people who are in it for the long haul.
                    </p>

                    <p className='about2'>This is not a fly-by-night operation, but rather an investment hub that will
                        leverage the very best that Web 3.0 has to offer in order to create a truly
                        revolutionary NFT ecosystem. By joining the Wolves of DOGE Street club as one of the
                        genesis members, you gain exclusive access to the community and all the projects
                        that are in store. These include free airdrops of the $WODS token, the airdrop
                        of ‘The Board’ utility NFT, the GEM project IDO allocation, and many more yet to
                        be announced.</p>
                    <p className='about2'>
                        Further down the line, we will leverage the resources and
                        expertise of the community to sponsor early-stage creators, developers,
                        and artists in the NFT space to give back to the community and to continue
                        growing the ecosystem as a whole. As a team, we believe that this space still 
                        has a lot of room to grow and we can help be one of the catalysts for the future 
                        of the industry.
                    </p>

                </div>

            </div>




        </div>
    )
}

export default About;