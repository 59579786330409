import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// Components

export const HeaderItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    transition: all ease-in-out 300ms;
    z-index: 100;
}
`

export const HeaderSubItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 300ms;
    @media (max-width: 950px) {
      background: var(--primary);
      width: 100%;

      justify-content: left;
      
      flex-direction: column;
      position: absolute;
      padding-top: 8px;
      align-items: center;
      left: 0;
      font-size: 2.2em;
}
`

//From Template

export const StyledLogo = styled.img`
  width: 90px;
  height: auto;
`;


export const StyledImgSmall = styled.img`
  width: 22px;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLink2 = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 500ms;
  font-size:0.7em;

  height: 30px;
  padding: 8px 14px;
  :hover{
    background-color: gray;
    
  }
  @media (max-width: 700px) {
    margin-bottom: 15px;
}
`;

const HeaderBeforeLaunch = () => {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  return (
    <div className='header'>
      <a href="/"><img className="brand_logo" alt={"logo"} src={"/config/images/logo.png"} /></a> 
      <HeaderItems>

        <HeaderSubItems className="bbb">


        </HeaderSubItems>

        <StyledLink2 target={"_blank"} href="https://twitter.com/WolvesOfDogeSt" >
            <StyledImgSmall alt={"example"} src={"/config/images/twitter_logo.png"} />
          </StyledLink2>

      </HeaderItems>

      <style jsx>{`
        .bbb{
          top: ${hamburgerOpen ? '0px' : '-320px'};
        }
      `}

      </style>
    </div>
  )
}

export default HeaderBeforeLaunch;