import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

import NftItem from "./NftItem";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

// Styled

//From Template

export const StyledButton = styled.button`
  padding: 5px 10px;
  border-radius: 7px;
  border: none;
  background: var(--gradient-yellow);

  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 120px;
  transform: scale(1.3);
  cursor: pointer;
  transition: all ease-in-out 400ms;
  :hover {
    transform: scale(1.4);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 800px) {
    flex-direction: row;
    width: 60%;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const Stake = () => {
  const dispatch = useDispatch();
  const showTokens = false;
  const [nftBalance, setNftBalance] = useState(0);
  const [nftIds, setNftIds] = useState([]);
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [stakingNft, setStakingNft] = useState(false);
  const [nftsShowing, setNftsShowing] = useState(false);
  const [feedback, setFeedback] = useState(`First, let's pull up your NFTs`);
  const [tokenId, setTokenId] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // Get NFT balance From Wallet
  const getBalance = () => {
    let internalNftBalance = 0;
    let listOfIds = [1, 2, 3];

    setFeedback(`Getting your NFT balance`);
    setStakingNft(true);

    // Getting balance of wallet
    blockchain.smartContractNft.methods
      .balanceOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log(receipt);

        setNftBalance(receipt);
        internalNftBalance = receipt;
        setFeedback(`Choose which NFT to stake (1 per transaction)`);
      })
      .then((receipt) => {
        setFeedback(`Getting your NFT Ids`);
        console.log("nftBalance = " + internalNftBalance);

        // Getting each of the IDs of the NFTs that user holds
        for (let i = 0; i < internalNftBalance; i++) {
          blockchain.smartContractNft.methods
            .tokenOfOwnerByIndex(blockchain.account, i)
            .call({
              from: blockchain.account,
            })
            .then((receipt) => {
              console.log(`ID ${i} ====> ` + receipt);
              listOfIds.push(receipt);

              setNftIds((nftIds) => [...nftIds, receipt]);
            });
        }

        setFeedback('');

        setStakingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  // STAKING //
  const stakeNFTs = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 0);
    let totalGasLimit = String(gasLimit * 1);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);

    setFeedback(`STAKING your ${CONFIG.NFT_NAME}...`);
    setStakingNft(true);
    setNftsShowing(true);

    blockchain.smartContractStake.methods
      .stake(tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(
          "Sorry, something went wrong please try again. You might have rejected the transaction."
        );
        setStakingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `The ${CONFIG.NFT_NAME} is now Staked! Sit back and enjoy the profits.`
        );
        setStakingNft(false);
        setNftsShowing(true);

        dispatch(fetchData(blockchain.account));
      });
  };

  // #####################

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContractStake !== null) {
      dispatch(fetchData(blockchain.account));
      getBalance();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  //To show tokens
  const showtokens = () => {
    if (blockchain.account !== "" && blockchain.smartContractStake !== null) {
      true;
    } else {
      false;
    }
  };

  // To select NFT to stake
  const handleNftSelection = (event) => {
    setTokenId(event.target.value);

    console.log("NFT selected:", event.target.value);
  };

  return (
    <div className="staking_wrapper">

        <div className="staking_container">
            <ResponsiveWrapper className="staking" flex={1} style={{ height: "auto", width:'auto', padding: 10 }} test>
              <s.Container
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{
                  padding: 10,
                  borderRadius: 24,
                  border: "4px var(--secondary)",
                }}>
                {(
                  <>
                    {blockchain.account === "" ||
                    blockchain.smartContractStake === null ? (
                      <s.Container className="HERE" ai={"center"} jc={"center"}>
                        
                        <StyledButton
                          id="connect_btn"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          START
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>) : ( <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {feedback}
                        </s.TextDescription>
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          {/* <StyledButton
                            disabled={nftsShowing ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              getBalance();
                              getData();
                            }}>
                            {stakingNft || nftsShowing ? "BUSY" : "SHOW MY NFTs"}
                          </StyledButton> */}
                        </s.Container>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <s.SpacerMedium />
                          
                          <s.SpacerMedium />
                        </s.Container>
                        {/* Component to Show NFTs Balance */}
                        {/* Esto tiene que ser un componente */}
                        {showtokens ? (
                          <div>
                           
                            <div style={{textAlign:"center", color: "white", fontSize: "1.8em", marginBottom:"30px"}}>
                              {'Choose which of your ' + nftBalance + ' NFTs to stake (1/transaction)'}
                            </div>
                            <ul
                              className="nft_holdings_container">
                              {nftIds.map(function (item, i) {
                                return (
                                  <NftItem
                                    func={handleNftSelection}
                                    nftImg={`https://ipfs.io/ipfs/QmVwmpoUS5MaYwsUJt9dGYHWeu9nVDf2gC9pY4SUXZjL1D/${item}.png`}
                                    val={item}
                                    key={i}
                                  ></NftItem>
                                );
                                //    <input key={i}/> + <img className="nft_holdings" src={`https://ipfs.io/ipfs/QmVwmpoUS5MaYwsUJt9dGYHWeu9nVDf2gC9pY4SUXZjL1D/${item}.png`}></img>
                              })}
                            </ul>
                            <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}>
                            Selected Token Id: {tokenId}
                          </s.TextDescription>
                          </div>
                        ) : (
                          <div style={{ color: "white", fontSize: "3em" }}>
                            Your NFTs will show here
                          </div>
                        )}
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledButton
                            disabled={stakingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              stakeNFTs();
                              getData();
                            }}
                          >
                            {stakingNft ? "BUSY" : "STAKE"}
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )}
                <s.SpacerMedium />
              </s.Container>
              <s.SpacerLarge />
            </ResponsiveWrapper>
        </div>
    </div>
  );
};

export default Stake;
