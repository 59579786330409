export const teamMembers = [
    {
        name: "Darren",
        link: "https://twitter.com/0xWodsFounder",
        link2: "https://www.linkedin.com/in/darren-wods-985ab0242/",
        img: "/config/images/twitter_logo.png",
        img2: "/config/images/linkedin_logo2.png",
        role: "CEO",
        description: "Darren is an entrepreneur, investor, and proud father. He has been deep in the technology industry for many years now and is excited to be bringing his experience to the blockchain space."
    },
    {
        name: "Takashi",
        link: "https://www.linkedin.com/in/takashi-yamamoto-430335243/",
        img: "/config/images/linkedin_logo2.png",
        role: "CTO",
        description: "Takashi is a former programmer from Microsoft who brings invaluable industry knowledge to the table. He has been a crucial part of the local blockchain ecosystem for a number of years now."
    },
    {
        name: "Sandeep",
        link: "#",
        img: "/config/images/twitter_logo.png",
        role: "Head of Blockchain Development",
        description:"Sandeep is a former software developer at Oracle. In the Blockchain space, he has worked in a 1st tier blockchain team where he learned what it takes to succeed in this space."
    },
    {
        name: "Alfonso",
        link: "https://www.linkedin.com/in/alfonso-gonz%C3%A1lez-067271243/",
        img: "/config/images/linkedin_logo2.png",
        role: "Dapp Development Engineer",
        description: "Alfonso is a talented engineer of decentralized applications, and he is excited to be a part of the long-term roadmap for WODS."
    },
    {
        name: "Santi",
        link: "https://www.instagram.com/blockchain.addict/",
        img: "/config/images/ig_logo.jpeg",
        role: "NFT Project Lead",
        description: "Santi is a Full Stack Developer that has 5 years of experience in the Blockchain space under his belt, having participated in multiple web3 projects."
    },
    {
        name: "Hannah",
        link: "#",
        img: "/config/images/twitter_logo.png",
        role: "Marketing Director",
        description: "With 7 years of marketing experience, Hannah has transformed herself into a Web 3.0 business expert."
    },
    {
        name: "ND",
        link: "https://twitter.com/nocrapdesign",
        img: "/config/images/twitter_logo.png",
        role: "Art Director",
        description: "He's the Best. Period... You will see his work in all of our social media posts and our website."
    },
    {
        name: "Flint",
        link: "https://twitter.com/0xJefWods/",
        img: "/config/images/twitter_logo.png",
        role: "Discord",
        description: "Flint is our Discord expert. He makes sure that our Discord group is on point, fun and secure."
    },
    {
        name: "Delfina",
        link: "#",
        img: "",
        role: "Social Media Engagement Manager",
        description: "Delfi has been into Community Manager since forever. She makes sure our community is as long as a phone number."
    },
    {
        name: "Matt",
        link: "#",
        img: "",
        role: "Social Media Engagement Manager",
        description: "Matt also helps with engagement, together with Seb, they make sure our family is happy and attended."
    },
    {
        name: "Seb",
        link: "#",
        img: "",
        role: "Social Media Engagement Manager",
        description: "Seb adds a key component to our team, assuring our project gets the attention of the right people."
    }
];