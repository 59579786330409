import React from 'react';

function LaunchpadItem(props){
    return (
        <div className="lp_item">
            <img src={props.img} alt="member img" />

            <div className="lp_item_container">
                <p>{props.req}</p>
                <p><span>TBA</span></p>
                <p>{props.body}</p>
                <p>{props.pool_weight}</p>
                <p className='lp_poolweight'>{props.number}</p>
                <p className='lp_extra'>{props.extra}</p>
            </div>
        </div>
    )
}

export default LaunchpadItem;