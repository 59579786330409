import React from 'react';

function NftItem(props){
    return (
        <div className="nft_item">
            <img className='nft_holdings' src={props.nftImg} alt="nft img" />
            <br />
            <input onChange={props.func} type="radio" name='nft_id' value={props.val}/>

        </div>
    )
}

export default NftItem;