import React from 'react'

const Partners = () => {
    return (
        <div className='partners'>
            <h2>Partnerships</h2>
            <div className="partners_container">
                <div className="row1">
                    {/* <div className="partner_item"><img src="/config/images/logos/nft-calendar.png" alt="" /></div> */}
                    <a className="partner_item" href="https://nftcalendar.io/"><img src="/config/images/logos/nft-calendar.png" alt="" /></a>
                    <div className="partner_item"><img src="/config/images/logos/opensea-logo2.png" alt="" /></div>
                    <div className="partner_item"><img src="/config/images/logos/ethereum-logo.png" alt="" /></div>
                    <div className="partner_item"><img src="/config/images/logos/nft-logo.png" alt="" /></div>
                </div>
                <div className="row2">
                    <div className="partner_item"><img src="/config/images/logos/mintable-logo.png" alt="" /></div>
                    <div className="partner_item"><img src="/config/images/logos/polygon-studios.png" alt="" /></div>
                    <div className="partner_item"><img src="/config/images/logos/cointelegraph-logo.png" alt="" /></div>
                    <div className="partner_item"><img src="/config/images/logos/n-logo.png" alt="" /></div>
                </div>
            </div>

        </div>
    )
}

export default Partners