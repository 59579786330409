import React from 'react';
import * as s from "../styles/globalStyles";
import styled from "styled-components";
// Components
import Header2 from "./Header2";

const Ido = () => {
    return (
        <div className='d2e'>
            <Header2 />



            <div className="spacer" style={{ height: "10px" }} />

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title'>Utility</h2>
                <div className="about_container">
                    <div className="about_text">
                        <p className='about2'>
                            The $WODS token is a deflationary cryptocurrency that will be the foundation of the Wolves of DOGE Street Ecosystem.  This is the token that will support DOGE2EARN and all the projects that fall under that umbrella.
                        </p>
                        <p className='about2'>
                            Some of the components of utility include the following:
                        </p>

                        <ul >
                            <li className='about2'>
                                •	Launchpad Staking
                            </li>
                            <li className='about2'>
                                •	Trade Payments throughout the NFT Marketplace
                            </li>
                            <li className='about2'>
                                •	Transaction Fees
                            </li>
                            <li className='about2'>
                                •	Currency for the DOGE2EARN Projects
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* ////////////////////////////////// */}

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title' id='tokenomics'>Tokenomics</h2>
                <div className="about_container">
                    <div className="about_text">
                        <p className='about2'>
                            10 billion $WODS tokens will be minted, with a 1% fee being burned on every transaction.
                        </p>
                        <p className='about2'>
                            The tokens will be distributed as follows:
                        </p>

                        <img className='tokenomics_img' src="/config/images/tokenomics.png" alt="" />

                        <p className="about2">
                            If you have any questions about the structure or the tokenomics in general, be sure to get in touch and we’d be happy to clarify any confusion.  We want the community to be integrally involved here and that starts with transparency and clarity across the board.
                        </p>

                        {/* <a href="#">Contact us</a> */}

                    </div>
                </div>
            </div>

        </div >
    )
}

export default Ido