import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { ethers } from "ethers";

import { whitelist } from "../whitelist.js";
import { whitelist2 } from "../whitelist2.js";

import { WHITELISTED_WITH_MERKEL_TREE } from "../whitelistKeys.js";
import { WHITELISTED_WITH_MERKEL_TREE2 } from "../whitelistKeys2.js";

import Header from "./Header2";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

// Styled
export const RowElement = styled.div`
  display: flex;
  font-size: 1.4em;
  font-weight: 600;
  color: var(--accent-text);
  border-bottom: 1px solid white;
  width: 100%;

  margin: 20px 0;
  padding-bottom: 10px;
`;

//From Template

export const StyledButton = styled.button`
  padding: 5px 10px;
  border-radius: 7px;
  border: none;
  background: var(--gradient-yellow);

  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 120px;
  transform: scale(1.3);
  cursor: pointer;
  transition: all ease-in-out 400ms;
  :hover {
    transform: scale(1.4);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10%;
  border: none;
  background-color: var(--primary);
  height: 30px;
  width: 30px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  width: 100%;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const Mint = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // CLAIMING NFT PUBLIC SALE
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    // let contractOwner = "";

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(
          "Sorry, something went wrong please try again. You might have rejected the transaction."
        );
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You have succesfully claimed your ${CONFIG.NFT_NAME}.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  // CLAIMING NFT WHITELIST
  const claimWhitelistNFT = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let numberOfWLNfts = 1;
    let totalCostWei = String(cost * 1);

    let whitelistKeysFile = {};
    let feedback = '';

    setFeedback(`Minting your Whitelist ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    if(
      whitelist2.includes(ethers.utils.getAddress(blockchain.account))){
        whitelistKeysFile = WHITELISTED_WITH_MERKEL_TREE2;
        numberOfWLNfts = 2;
        feedback = `You have succesfully claimed TWO ${CONFIG.NFT_NAME} as whitelisted user.`;
        console.log('found in whitelist 2');
    }
    else if(
      whitelist.includes(ethers.utils.getAddress(blockchain.account))){
        whitelistKeysFile = WHITELISTED_WITH_MERKEL_TREE;
        feedback = `You have succesfully claimed ONE ${CONFIG.NFT_NAME} as whitelisted user.`;
        console.log('found in whitelist 1');
    };

    let totalGasLimit = String(gasLimit * numberOfWLNfts);

    blockchain.smartContract.methods
      .mintWhitelist(
        whitelistKeysFile[
          ethers.utils.getAddress(blockchain.account)
        ]
      )
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(
          "Sorry, something went wrong please try again. You migth have already claimed your Whitelist NFT."
        );
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(feedback);

        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  // #####################
  // TEST

  const validateAddress = () => {
    let smartContractAddress = CONFIG.CONTRACT_ADDRESS;
    let validation = false;

    console.log(smartContractAddress);
    console.log(validation);

    blockchain.smartContract.methods
      .walletOfOwner(blockchain.account)
      .call()
      .then((receipt) => {
        console.log(receipt);
        if (receipt.length != 0) {
          validation = true;
        }
        setFeedback(
          `Validation went through ===> This user owns the following NFT: ${receipt} `
        );
        dispatch(fetchData(blockchain.account));
      });
  };

  // #####################

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };
  const setMaxMintAmount = () => {
    setMintAmount(2);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  // const [isHovering, setIsHovering] = useState(false);

  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };

  return (
    <ResponsiveWrapper className="mint_pre_wrapper">
      <Header/>

      <div className="mint_wrapper">
        <div className="mint_l">
          <div className="mint_l_container">
            
            <div className="mint_description">
            <p>A revolutionary new NFT project whose goal is to do one good thing every day.</p>

            </div>
            <div className="img_and_vid">
                <video className='VideoTag' width="300" height="300" autoPlay loop muted>
                    <source src={"./config/images/vid/wods_vid.mp4"} type='video/mp4'/>
                </video>
            </div>

          </div>
        </div>

        <div className="mint_r">
          <div className="mint_box">
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    borderBottom: "1px solid white",
                    width: "80%",
                  }}
                >
                  Sold Out!
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Secondary sales {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    {/* <s.TextDescription
                                        style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            width: "60%"
                                        }}>
                                        NETWORK: {CONFIG.NETWORK.NAME}
                                    </s.TextDescription> */}

                    <s.SpacerSmall />
                    <StyledButton
                      id="connect_btn"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      {/* MINT SOON  */} CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                    <s.SpacerMedium></s.SpacerMedium>
                    <p style={{fontSize:"1.2em", textAlign:"center"}}>You can easily import your wallet to METAMASK by following  <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489331-How-to-import-an-account" target={"blank"}>this tutorial</a>  </p>

                    <s.SpacerMedium/> 

                    <p>Use <a href="https://ethereumprice.org/gas/" target={"blank"}>this tool</a> to track GAS prices</p>
                  </s.Container>
                ) : (
                  <>
                    <p style={{ fontSize: 30, fontWeight: "600" }}>MINT</p>
                    <RowElement>
                      Total: {data.totalSupply} of {CONFIG.MAX_SUPPLY}
                    </RowElement>
                    {/* Select Quantity */}
                    <RowElement>
                      <p>Quantity</p>
                      <s.SpacerMedium />

                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          marginBottom: "0px",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>

                      <s.SpacerMedium />

                      <button
                        className="max_btn"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          setMaxMintAmount();
                        }}
                      >
                        Max
                      </button>
                    </RowElement>
                    {/* Price */}
                    <RowElement>
                      Total Cost: {CONFIG.DISPLAY_COST * mintAmount}{" "}
                      {CONFIG.NETWORK.SYMBOL}
                    </RowElement>


                    {/* ALERT 1 & 2 */}
                    
                    <s.SpacerSmall />
                    {blockchain.account!=null && whitelist.includes(
                      ethers.utils.getAddress(blockchain.account)
                    ) ||  
                    blockchain.account!=null && whitelist2.includes(
                      ethers.utils.getAddress(blockchain.account)
                    ) ? (
                      <s.Container
                        style={{ marginBottom: "10px" }}
                        ai={"center"}
                        jc={"center"}
                        fd={"row"}
                      >
                        <StyledButton
                          style={{
                            background: "white",
                            color: "black",
                            width: "80%",
                          }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimWhitelistNFT();
                            getData();
                          }}>
                          {claimingNft ? "BUSY" : "Whitelist MINT"}
                        </StyledButton>
                      </s.Container>
                    ) : null}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <p>ATTENTION: if you're entitled to 2 whitelist Spots, clicking only 1 time will send you your TWO NFTs</p>
                    {/* MINT button */}
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      style={{
                        marginTop: "20px",
                        width: "80%",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "MINT"}
                    </StyledButton>
                    
                    <s.SpacerMedium />
                    {/* Display Wallet */}

                    {/* ALERT 3 */}

                    {blockchain.account!=null ? 
                    <div><p>You're connected with:</p>{ethers.utils.getAddress(blockchain.account)}</div>
                    :
                    null  
                  }


                    
                    
                    {/* {ethers.utils.getAddress(blockchain.account)} */}
                    {/* {WHITELISTED_WITH_MERKEL_TREE[ethers.utils.getAddress(blockchain.account)]} */}
                  </>
                )}
              </>
            )}

            {/* <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                            }}
                        >
                            <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                        </s.TextDescription> */}
          </div>
        </div>
      </div>

      <s.SpacerLarge />
    </ResponsiveWrapper>
  );
};

export default Mint;
