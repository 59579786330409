import React from 'react'
// Components
import Header2 from "./Header2";

const DogeRunner = () => {
    return (
        <div className='d2e'>
            <Header2 />

            <div className="spacer" style={{ height: "10px" }} />

            <div className="d2e_wrapper" style={{ borderTop: "none" }}>
                {/* <h2 className='about_title'>DOGE2EARN</h2> */}
                <img className='d2e_img' src="/config/images/d2e/doge2earn.png" alt="title" />
                <div className="about_container">
                    <div className="about_text">
                        <p className='about2'>
                            DOGE2EARN is a decentralized app (DApp) development program that will be a driving force within the Wolves of DOGE Street Ecosystem.  The mission is to create a suite of applications that allow investors to earn passive income while improving their lives, connecting with others, and having a lot of fun along the way.
                        </p>
                        <p className='about2'>
                            The first phase of this program will be led by the WODS development team as a proof of concept and to get the ball rolling.  From Phase 2 onwards, we’ll open up the program to welcome start-ups and studios who want to launch their own DApps under the WODS umbrella.  This will function as a sophisticated incubator of Web 3.0 applications and represents the most exciting component of being a part of this community.
                        </p>

                        <p className="about2">
                            In addition, the WODS foundation will play a significant role in sponsoring these new developers and creators, as well as mentoring the project owners as they bring their creations to life.  This will ensure a high-quality result that fits seamlessly into the rest of the ecosystem.
                        </p>


                    </div>
                </div>
            </div>

            {/* ////////////////////////////////// */}

            <div className="d2e_wrapper" style={{ borderTop: "none" }}>
                {/* <h2 className='about_title' id='dogerunner'>DOGE RUNNER</h2> */}
                <img className='d2e_img' src="/config/images/d2e/doge_runner.png" alt="title" />

                <div className="about_container">
                    <div className="about_text">
                        <p className='about2'>
                            The first DApp under this program is known as DOGE Runner.  This is a move-to-earn app that encourages users to keep active and get that fresh air that we all know that we need more of.  When users walk or run with the app, they’ll be rewarded with $WODS for their efforts, according to various criteria.
                        </p>
                        <p className='about2'>
                            To take things further, users can even bring their real-life DOGE (or dog, as the plebs will say) and increase their earning amount thanks to a dedicated GPS-enabled dog leash that tracks the dog’s movement.
                        </p>

                        <p className="about2">
                            This app is the first of many that will aim to create a vibrant and healthy ecosystem for members of the Wolves of DOGE Street – where we can push each other to be better, stronger, and healthier than we were before.
                        </p>


                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

            <img className='wods_band' src="/config/images/d2e/wods_band.png" alt="" />

            </div>

        </div>
    )
}

export default DogeRunner