import React from 'react';
import Collapsible from 'react-collapsible';

const Roadmap = () => {
    return (
        <div className='roadmap' id='roadmap'>
            <h2><span className='span'>STREETMAP</span> </h2>

            {/* Visual roadmap */}

            <div className='visual_rm_container'>

                <div className="rm_column2">

                    <div className="rm_phase_wrapper">
                        <img className='rm_phase_logo' src="/config/images/rm/rm_p1.png" alt="roadmap logo" />

                        <div className="rm_phase_container">
                            <div className="rm_phase_line"></div>
                            <div className='rm_phase'>
                                <Collapsible trigger="Q2 2022">
                                    <p>• Setting up the community ecosystem across Twitter and Discord</p>
                                    <p>• Creation of the official website</p>
                                    <p>• Showing sneak peeks of the NFTs to drive awareness and publicity</p>
                                </Collapsible>
                            </div>
                        </div>
                    </div>

                    <div className="rm_phase_wrapper">
                    <img className='rm_phase_logo' src="/config/images/rm/rm_p2.png" alt="roadmap logo" />


                        <div className="rm_phase_container">
                            <div className="rm_phase_line"></div>
                            <div className='rm_phase'>
                                <Collapsible trigger="Q3 2022">
                                    <p>• Minting of “The Genesis” “The Board” “The Board Premium” NFTs</p>
                                    <p>• Partnership announcement</p>
                                    <p>• Launch of the NFT Staking Centre (V1.0)</p>
                                </Collapsible>
                            </div>
                        </div>
                    </div>

                    <div className="rm_phase_wrapper">
                    <img className='rm_phase_logo' src="/config/images/rm/rm_p3.png" alt="roadmap logo" />


                        <div className="rm_phase_container">
                            <div className="rm_phase_line"></div>
                            <div className='rm_phase'>
                                <Collapsible trigger="Q4 2022">
                                    <p>• $WODS IDO and DEX Listing</p>
                                    <p>• Release of the GEM Launchpad (V1.0)</p>
                                    <p>• $WODS 1st tier CEX Listing</p>
                                    <p>• Launch of the NFT Foundation</p>
                                </Collapsible>
                            </div>
                        </div>
                    </div>

                    <div className="rm_phase_wrapper">
                    <img className='rm_phase_logo' src="/config/images/rm/rm_p4.png" alt="roadmap logo" />


                        <div className="rm_phase_container">
                            <div className="rm_phase_line"></div>
                            <div className='rm_phase'>
                                <Collapsible trigger="Q1 2023">
                                    <p>• Launch of the WODS Lab.</p>
                                    <p>• NFT Marketplace Launching (Beta).</p>
                                    <p>• GEM Launchpad upgrade (V2.0)</p>
                                </Collapsible>
                            </div>
                        </div>
                    </div>

                    <div className="rm_phase_wrapper">
                    <img className='rm_phase_logo' src="/config/images/rm/rm_p5.png" alt="roadmap logo" />

                        <div className="rm_phase_container">
                            <div className="rm_phase_line"></div>
                            <div className='rm_phase'>
                                <Collapsible trigger="Q2 2023">
                                    <p>• Launch of the DOGE2EARN Program</p>
                                    <p>• Doge Runner DAPP launching (Beta)</p>
                                    <p>• NFT Staking Centre upgrade (V2.0)</p>
                                    <p>• Launch of the NFT Marketplace (V1.0)</p>
                                    <p>• Announcement of real-world business partnerships</p>
                                    <p>• Release of the 2023-2024 Roadmap</p>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="after_rm_text">
                <p>This is our vision for the future of the WODS ecosystem.
                    However, we’re only one part of this puzzle.
                    When you become a member of the Wolves of Doge Street, you’ll also get
                    your voice in how we leverage the resources at our disposal to grow
                    the community and to invest in the sorts of projects that you’re excited
                    about.</p>
                <p>If you have comments, suggestions, or
                    ideas on how we can improve this product roadmap,
                    get in touch and let us know. We’re building this
                    with the community, for the community – and so your
                    feedback is not only encouraged but crucial for our long-term
                    success.
                </p>
            </div>

        </div>
    )
}

export default Roadmap;