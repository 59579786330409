import React from 'react'

import Header2 from "./Header2";

const Nft = () => {
    return (
        <div className='d2e'>
            <Header2 />

            <div className="spacer" style={{ height: "10px" }} />

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title'> The Genesis NFT</h2>


                <div className="about_container">

                    <div className="about_text">
                        <p className='about2'>In order to be a part of the community going forward, you must first be an owner of a genesis WODS NFT.  This token is your membership access pass to collaborate and connect with the community at large.  The NFT is inspired by the Shiba Inu that started it all, but it adds a twist.  By transforming it into a more wolfy look, you get heaps of personality, a strong sense of independence, and a gritty exterior that represents our attitude as an organization.
                        </p>
                        <p className='about2'>
                            Genesis WODS holders can survive anything, regardless of what life throws at us.
                        </p>
                    </div>

                </div>

                <div className="genesis_content">

                </div>
            </div>

            {/*  ///////////////////////////////////////////////////////////  */}

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title' id='the_board'>The Board NFT</h2>

                <div className="about_container">

                    <div className="about_text">
                        <p className='about2'>The Board NFT is the next stage after the genesis.  All owners of the WODS NFT will get a free airdrop of this non-fungible token.</p>
                        <p className='about2'>This will be the driving force of the WODS community and ecosystem.  Essentially, this token gives you a board seat on the most exciting new blockchain community out there.  The utility is mind-blowing.</p>
                        <p className='about2'>Here are some of the things you’ll get as an owner of The Board NFT:</p>

                        <ul >
                            <li className='about2'>•	Access to the community digital asset acquisition club.  This is an inner circle of buyers who will have the opportunity to co-own or own outright various metaverse properties, including the likes of Sandbox, DECENTRALAND, and OTHERSIDE via our NFT marketplace.</li>
                            <li className='about2'>•	IDO Public sale allocations from GEM projects.  We expect 1-2 GEM projects IDO to be available every 4-6 weeks on WODS IDO launchpad, and there will be no token staking required for holders of “The Board” NFT.</li>
                            <li className='about2'>•	Staking of NFTs for passive income.  If you’re willing to participate in IDOs you can stake your NFTs and earn extra side income along the way.</li>
                            <li className='about2'>•	Support the next generation of NFT projects.  The WODS foundation will sponsor and invest in a range of new projects and while the WODS GENESIES holders will receive 5% of these new NFTs, holders of The Board NFT (holding GENESIS NFT REQUIRED) will receive an additional 5% on top of that.</li>
                            <li className='about2'>•	Rebates from trading fees.  Receive an instant 10% rebate on any NFT trade fees.</li>
                            <li className='about2'>•	Access to real-world investment opportunities.  By holding The Board NFT, you’ll be a part of our long-term mission to find and acquire real-world businesses that align with the community more generally.</li>
                        </ul>

                    </div>
                </div>
            </div>

            {/*  ///////////////////////////////////////////////////////////  */}

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title' id='the_board'>The Board Premium NFT</h2>

                <div className="about_container">

                    <div className="about_text">
                        <ul >
                            <li className='about2'>•	The Board Premium” NFT holder has full functional access in the ecosystem.</li>
                            <li className='about2'>•	No GENESIS NFT holding required for WODS foundation sponsored NFT project air-drop.</li>
                            <li className='about2'>•	The Board Premium NFT provides institutional level of investment opportunity, holders have access to “Public + Private + Seed” rounds GEM project allocations. </li>
                        </ul>

                    </div>
                </div>
            </div>

            {/*  ///////////////////////////////////////////////////////////  */}

            <div className="about_wrapper" style={{ borderTop: "none" }}>
                <h2 className='about_title' id='the_board'>NFT Marketplace</h2>

                <div className="about_container">

                    <div className="about_text">
                        <p className='about2'>
                            Coming Soon
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nft