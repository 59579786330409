export const whitelist2 = [
  "0x6D535adBFB1E128eE65968400bF89540F707291b",
  "0x4cbb0dDBb34A00F8057929C23464c3b2C102140a",
  "0x4F94b0FC49B73121a23E0088D6bcC195Cbb9313e",
  "0xB394393ce8548E25fe4E01e962d683297adB0fC1",
  "0x652a58dc38a1588110b9b864B4fF92178F5Aa3a4",
  "0x48A5BA12b8302Ed2c9eBb90BF6A882fE045a3a4B",
  "0xE97d4797698d8f9Cf3538C2A0eB79F35FFc13620",
  "0xa7ff63aE5827fa5562a7C0ce16f64C906C691D19",
  "0xa9D87A25f09F522b2D63eBe4c0bb69e078C2B67e",
  "0x93be52bCC75040E2257C0F573497CCe858B904E4",
  "0x3Cb1317C82EC24464202035D66B9A85e833999D4",
  "0x519011F2Df95675c803cc39568bE20c7D4F7e947",
  "0xa8ac9C0403d8a31cfC1Bf43e09231C0FfAf52dd7",
  "0x26ce989194034d0d3b6d2df2360C57C7B054Fc01",
  "0x9E1e7c1fBB06cDB3ccbECc731e738B559a471B81",
  "0xE3d39a7537fe7E37D893F7f15EC031067808C11b",
  "0xA2dC61ccf1C0B8dd305b82DA1F43FE8289118fa7",
  "0x71F5483824e1dBaD6a467440DF0d26d169E726AD",
  "0xB76b71141Da89c367Fa02ae8C81F85646Ba70CDA",
  "0xC463fb51054D0608FD32262Bc73bdF9fAf78B239",
  "0xfcD416725E4eaBeA10b0D1cEc46697b049079EA9",
  "0xA24F40722eb735e8Cb00760D448374c7fED2DCB3",
  "0x07B0EFF374596AD0BE9eaCCa803B55b89269dA6E",
  "0x94b39C5cD4f8beB505e5Cf30FF44306260Ed2E83",
  "0x9DDf353cB6f51Adaf352b3965eFF16B154137e02",
  "0xC6fAEfBACE8731ff78fB0855c405C6bE4EE26eaF",
  "0x4026b69FA2c6F84d7a3B6E348e423A62F3AC3c59",
  "0xef32ee1B480E26aeAc3AB02682174BB87227470d",
  "0xDC32627a855552fbDdc8307185317f004FA17C80",
  "0x3980c094a49592e9d25F9c82FE88335b7C6FC83C",
  "0x04DB9eA29E45d02C3795656f3B80f4bE708676bb",
  "0xe5Be78Ce3A0D4007661B3734d3Cb723F8FB3e871",
  "0xB254d5d9a4290f081e8258598eCdd74C822d01d0",
  "0x17B65A606d4aBd1AA73FaCB2B84fbB41fBe46542",
  "0xC74B707b122fd65c98957b5F30392208ECf74317",
  "0x22232c9323f76EB1fB68cf6c0A1148E5588FF789",
  "0x601D9A3d680b62e45b75e7d616E1024f1F5A3204",
  "0xFC1586Bdefb200d3FD558AC301398172bd144Ce8",
  "0xBC6124e38d654cBB0F340C5ae972C337a1cc5Ce6",
  "0x2048824e9116440a8BCfC8D15342de78D355B03D",
  "0x28A4ebEd47c293A193340F8A57BA7f56f33baEBd",
  "0xb7f1395d19bffc306Cf85A71DC0b06dF57a25De2",
  "0x236519DE0767A3a5cA9219A5Cfd22043E5f12E47",
  "0x6A0C78Ad84367DEE081564c926fEF40e270d4B2b",
  "0x15e21CbFE74514D4D39e0edA92AF6AfaeD2201AD",
  "0xC3867481122A2e77E63EdeaAe5a1Eecd1FD879b7",
  "0xeeDBfdE8d6355CFc42EE9ad77404202b6f2Fea63",
  "0x38EBf81D471f819765852a22300A6A3042B11682",
  "0xf5EceF47CACDbAf821d60A49989A3b5274F065F1",
  "0x61B3080e4aa5a3e781C3387a396F84020AF6038E",
  "0x1D4E61e7179F91bafCb5B7eA481e40647a788149",
  "0x8427fDa74126FAc968A99C2b21cCaceF2bC43D30",
  "0x0E2D9068A37259dC2C48A36540aC897D5dddf802",
  "0xf715e1Fb5b5052Fef24Cf4b57D7D5AA0a3EB171C",
  "0x93C78550Fd0644B483C91925A7277a0Ae6Cf960C",
  "0x681BDc1E4Fe7B43387553dE667556Dc1cEF6469D",
  "0x4Fb65085e45D953C9a4C69D3b76D391074A03713",
  "0x84fae7dAFFC043aB8B6399050b3C4D704cF84eDf",
  "0x65f4Ed2F91E3D0e3526629b8f26eFd8493BDd388",
  "0xdAff532b2F0EE11d214F473839f2822EABfe46E4",
  "0x5E398630d80aADa034182FA23bea4424FDc3E6AA",
  "0xf60F5A2b7423dAD3e630BF9F1747022f57BF41B8",
  "0x43b60e7345a7486fbFA7b9809be9F322570bdffD",
  "0x4D2AdDAC831290f1c8533625bEc0F3C0B13503c7",
  "0x656A8e578855b5C794D7EAce651A1D2a88e016F1",
  "0xCe7f1F86c03c85d607AA3bf7e9D516364eD537Fe",
  "0x9e719eEa72055078151A9a43c4f503467c2F39AC",
  "0xcBde74Fb988030d7383A03746070a0C57Aaf6b60",
  "0x868f26fd4268EeD84Cf4B820f518aF61607BCa14",
  "0x01acF54367f1BbeABC060B8eFfAB552C5b167b40",
  "0xC32409BA2Ed21b427A20EBa2b5E754064Dd70c85",
  "0xaA4585EB409F6c6c5Ecc19c5974D10aAF34835AF",
  "0x44505d6b17bC24e1E8260C130A0AE4B72f47275D",
  "0xFD497C350260d749F57A1279fa3AA73D421aECFd",
  "0x8e339C293738d9CC5fa2888134D22449EDFE85ac",
  "0x2c5a33A6a59Eb29fd05185EE6fD819874509f0E2",
  "0x220df1D4Fc558B433CC47b893F54F06CeD0F990e",
  "0x3198F1e2570E1dfd97e58D9b530Faf8BBD542cb1",
  "0x3C5d5392e74F795E16b780cCb2F6B7c04eb983E6",
  "0x5999ba0EFFBbc2B23Ef98cEf80AB59F218162bf7",
  "0x9807AF0Bb2465228d396314316FD178f23F14Cf3"
];