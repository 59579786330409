import React, { useState } from "react";

const Footer = () => {

  // To copy the email to the clipboard when clicking
const copyToClipboard = () => {
  navigator.clipboard.writeText('collabs@wods.club')
}

// To show a message when hovering contact
const [isHovering, setIsHovering] = useState(false);

const handleMouseOver = () => {
  setIsHovering(true);
};

const handleMouseOut = () => {
  setIsHovering(false);
};


  return (
    <div className="footer">
      <div className="footer_links">
        <ul className="footer_menu">
          <li>
          {isHovering && <p style={{position:'absolute', bottom:'50px', left:"30%"}}>Click to copy</p>}
            Contact Us: <button onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={copyToClipboard} style={{fontSize:'1em', border:'none', background:'none', color:'white', cursor:'copy'}}>collabs@wods.club</button>
          </li>
          <li>
            {/* <a href="#">Partnerships</a> */}
          </li>
        </ul>
        <ul className="footer_menu">
          <li>
            <a href="https://discord.com/invite/wods">Discord</a>
          </li>
          <li>
            <a href="https://twitter.com/WolvesOfDogeSt">Twitter</a>
          </li>
          <li>
            <a href="https://opensea.io/WODSNFT">Opensea</a>
          </li>
        </ul>
      </div>

      <div className="absolute_elements_footer">
        {/* <p className='footer_credit'>Website by <a target={"blank"} href="https://www.instagram.com/blockchain.addict/">@blockchain.addict</a> (IG)</p> */}
        <p className="footer_copyright">
          © 2022 WODS, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
