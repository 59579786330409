import React from "react";

import styled from "styled-components";
import Header from "./Header2";
import { launchpadItems } from '../launchpadItems';
import LaunchpadItem from "./LaunchpadItem";


export const StyledButton = styled.button``;

const Launchpad = () => {
  return (
    <div className="launchpad">
      <Header />
      <div className="launchpad_wrapper">
        <h2>WODS IDO Launchpad</h2>
        <h3 className="launchpad_subtitle">- A REAL GEM GENERATOR</h3>
        <p className="launchpad_subtitle2">1-2 NEW Gem projects IDO <br /> every 4 to 6 weeks!</p>

        <div className="hero_launchpad">
          <div className="hero_launchpad_container">
            <StyledButton>APPLY FOR IDO</StyledButton>
            <p>(Coming Soon)</p>
          </div>
          <div className="hero_launchpad_container">
            <StyledButton>BUY $WODS</StyledButton>
            <p>(Coming Soon)</p>
          </div>
         
        </div>

        <div className="launchpad_main">
          <h3>WODS Launchpad tier system</h3>
          <p>
            The 1.0 version of our Launchpad will have 6 tiers, based on 1
            lottery and 5 guaranteed allocation tiers with a pool weight
            formula.
          </p>
          <br />
          <p>
            In order to access the IDO allocation, please refer to the tier
            system below to secure your IDO spot.
          </p>
        </div>

        <div className="launchpad_tiers">
        {launchpadItems.map((item, key) =>{
                    return <LaunchpadItem img={item.img} req={item.req} body={item.body} pool_weight={item.pool_weight} number={item.number} extra={item.extra}  key={key}/>
                })}
        </div>

        <div className="launchpad_bottom">
          <h3>Staking Lock period option: <span>TBA</span></h3>
          <p>Stakers will be able to stake their NFTs and use the built-in calculator
            to calculate the APY from each lock period options (coming soon)</p>  
            <h3 className="launchpad_bottom_lasth3">Launching: October 2022</h3>

        </div>

      </div>
    </div>
  );
};

export default Launchpad;
