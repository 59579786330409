export const menuItems = [
    {
     title: "About us",
     submenu: [
         {title: "About", link: "#about"},
         {title: "Team", link: "#team"},
     ]
    },
    {
     title: "NFT",
     submenu: [
         {title: "GENESIS", link: "/nft"},
         {title: "THE BOARD", link: "/nft/#the_board"},
         {title: "NFT Marketplace", link: "/comingsoon"},
         {title: "NFT Foundation", link: "/comingsoon"},
         {title: "Gallery", link: "/comingsoon"}

     ]
    },
    {
     title: "Tokenomics",
     submenu: [
         {title: "IDO", link: "/ido"}
     ]
    },
    {
     title: "DOGE2EARN",
     submenu: [
         {title: "DOGE Runner", link: "/doge2earn"},
         {title: "WODS Lab", link: "/doge2earn/#dogerunner"},
     ]
    },
    {
     title: "Launchpad",
     submenu: [
         {title: "Dapp", link: "/launchpad"},
         {title: "Staking Center", link: "/comingsoon"},
     ]
    },
    {
     title: "Info",
     submenu: [
         {title: "Streetmap", link: "#roadmap"},
         {title: "Pitchdeck", link: "https://docsend.com/view/wpuvb9ir2zxk7axa"},
         {title: "Whitepaper", link: "#"}
     ]
    },
    {
     title: "Investor Form",
     submenu: [
         {title: "Go to investor Form", link: "#"}
     ]
    }

];