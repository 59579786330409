export const launchpadItems = [
    {
        img: '/config/images/tiers/pandamoto-wods.png',
        req: '$WODS Staking Requirement',
        body: 'Twitter collab for whitelist. Follow, Like, Retweet & Tag friends. Lottery based.',
        pool_weight: 'Guaranteed Poolweight',
        number: '1',
        extra: ''
    },
    {
        img: '/config/images/tiers/tiger-wods.png',
        req: '$WODS Staking Requirement',
        body: '',
        pool_weight: 'Guaranteed Poolweight',
        number: '3',
        extra: ''
    },
    {
        img: '/config/images/tiers/pink-punk-wods.png',
        req: '$WODS Staking Requirement',
        body: '',
        pool_weight: 'Guaranteed Poolweight',
        number: '6',
        extra: ''
    },
    {
        img: '/config/images/tiers/doge-wods.png',
        req: '$WODS Staking Requirement',
        body: '',
        pool_weight: 'Guaranteed Poolweight:',
        number: '12',
        extra: ''
    },
    {
        img: '/config/images/tiers/the-board.png',
        req: '$WODS Staking Requirement',
        body: '',
        pool_weight: 'Guaranteed Poolweight:',
        number: '24',
        extra: '(No Staking requirements for "The Board" NFT Holders)'
    },
    {
        img: '/config/images/tiers/the-board-premium.png',
        req: '$WODS Staking Requirement',
        body: '',
        pool_weight: 'Guaranteed Poolweight:',
        number: '70 + Private + Seed Allocation',
        extra: '(No Staking requirements for "The Board Premium" NFT Holders)'
    },
]