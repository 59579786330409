export const whitelist = [
  "0x9bd23a97689401C87484Eda99eD29dB3DB815bF3",
  "0xE266433a0a71baC6a93A249865d58FE5B1cd794D",
  "0x62d3836F12FDAB51201901c04c8F067f9DCF8FEb",
  "0x63F40AB919f175F8473c56B19C839657c2c610AC",
  "0x949e647aF8855E82f66721cBB648EA0259eCf4Fa",
  "0x3aAC9E03AD32e42DC11482aA3FAeCCD8FF4d21bA",
  "0xe6e502B2b3AEe86FF864d69139239bF0449c33Ea",
  "0x78C62a423A738b52edCc96F1BBc89823eda92B67",
  "0xe4E9Ed12018E341b579E4fcB9A531435922aDB1F",
  "0x21FBA6460c6C3A924cB919120CB9a12708BF84Bb",
  "0x483691FDbeDF5b2cF9c25Ba1785f8010EDCb4AF7",
  "0xE3fbB9081c10a3225EFE9476aB4916719438a0aA",
  "0xd19c473DeD202Cfd565734Ae42F55aaadfe95E19",
  "0x9F3416ecDff13C326851DAA8D5353ba94deb5c67",
  "0xd1e449fC7d8882D5a04890a1D113cDD7020F824D",
  "0x36e84e33a8627220477c68B49165288B06d6D33B",
  "0x9BFa4630d5edF6Fb710FCf495E9f53a3934c069d",
  "0x7ca65C034c1C1120764B676Aa168B1a95bc0347e",
  "0xF8764000Ae01D29D195eb79248B8e31230FEFd9C",
  "0x0341cb3Ca2cddC59AddfDEE4c1f31DCa3E660BA0",
  "0x2f2D2ec37c14C590D260A9D0fA775022396E602E",
  "0x268E0001F1ED6FA9F7162Ee18dF8c0DF4173a2AA",
  "0xF7b768Dd9A011e9A3F55052F69A2Ad9b8d7a1Db6",
  "0x4c9f0ff1015a1D63C35907e57E36817f1a24C261",
  "0x99A8220874c0ca868eB628b92Be11375CCEF11D8",
  "0x08D519D3530a5291786dd65c1bDf6EAA02B36Ddc",
  "0x89E7d88ff5D25aB83F2310F4696c3C78298B84BD",
  "0xEda2f4A9fb5d345D5ea0f11c35435A010Bf6E356",
  "0x55d398326f99059fF775485246999027B3197955",
  "0x75862848bD405710e80286a7273e72b8F1eb01D7",
  "0xC1790d6A024207826763c290fBa05361Ce046bD6",
  "0xDb56a75575e014BfFfAEc9B7688e810953B453bF",
  "0xCDfE20B2D39cf689085A55724C691f7A678292b1",
  "0x3655810F2eD910Cb72401B00261C33Fc2FD19B21",
  "0x382d93FB6F42Cc883c7e2d9422f3DA34334953e2",
  "0x077c68BA99769E330761a121205895C214D94bDa",
  "0x0c5d23eb5FD4aF7234A75874793C8029ccfa779a",
  "0x4Ae813B56A31942892BE927f2E1de5217aFa556e",
  "0x0031c7210563db567329dBbDEc2ef3722B637C53",
  "0x7a1c54d70eFB5A67E1Eb2EDE6CBe367F9504478c",
  "0x0e5361f56b99392170087ef30F808B3e74081403",
  "0x68FCbaae304642969F646187550BCD104750118c",
  "0x6682200B03c60447F7EC614aeA15F46c6E81648c",
  "0xD21b28bDCd8a81fcCA41Be237861B7a8Ef69e2ae",
  "0xC2C8BF4bD915cB8Aa15CF42b5aD22991B525f5ED",
  "0xC189b7380783391b71eD64985AA89Da80F1e779e",
  "0xede6D0686241e853Abe4A6C66b56e2b068bdA70f",
  "0x7992d3EAAd1d03b69e89085bcf0E66777f183a65",
  "0x414963f7fD4269D7A8344381F83208D7d0AAb395",
  "0xe80Ee3E41f820CFbEBa7D6f3E628Bb1Dc3154dd0",
  "0xE67f9C0b3a53BB6edDEbe4816a8F145357DC358f",
  "0xFBab97A84F202A8C4545A57213601Bd413DA8bcb",
  "0x2797E38E081085739E945039E3742B93548255A0",
  "0x5999ba0EFFBbc2B23Ef98cEf80AB59F218162bf7",
  "0xAf8A05c18092C8A16604076C2D4Cb621552Aa7EF",
  "0x354717E571db62A35d99d0bE4838CEae35B92e38",
  "0xAA17A7426fFcC6DbdE14C22fe76Da5129919D3b3",
  "0x4234262a9C6cF52989dc74cbBCac5Df7b6d7eD9F"
];