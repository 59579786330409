import React, { useEffect, useState, useRef } from "react";
import * as s from "../styles/globalStyles";

import Header2 from "./Header2";
import Mint from "./Mint";
import Roadmap from "./Roadmap";
import About from "./About";
import Team from "./Team";
import Footer from "./Footer";
import Partners from "./Partners";

const Home = () => {

  // Toggle
  // const [isToggled, setIsToggled] = useState(false);

  return (
    <div>
     <Header2/>

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, background: "var(--primary)" }}>

        {/* Gif IMAGE */}
        <div className="hero">
          <div className="hero_container">
            <h2>WELCOME TO THE WOLVES OF DOGE STREET</h2>
          </div>

          {/* <div className="mint_link2"><a href="/mint">MINT</a></div> */}

          <img src="/config/images/website_banner_full.png" alt="hero img" />
        </div>

        <div className="home_first_row">
          <div className="first_row_left">
            <h2><span className="span">Wolves of Doge Street</span></h2>
            <p>A revolutionary new NFT project whose goal is to do one good thing every day.</p>
            <p>Paying homage to some of the most culturally important NFT projects of our time, we’re excited to
              launch the Wolves of DOGE Street – a community NFT community
              and investment hub that will leverage the new innovations of
              Web 3.0 to grow the next generation of artists and creators.</p>
            
              <div className="mint_link"><a href="/mint">MINT</a></div>

            {/* <Mint /> */}
          </div>

          <div className="first_row_right">
            <img src="/config/images/about-img.png" alt="animated gif" />
          </div>
        </div>

        <s.SpacerMedium />

        <About />

        <Team />

        <Roadmap id='roadmap'/>

        <Partners />

      </s.Container>

      <Footer />

    </div>


  )
}

export default Home