import React, { useEffect, useState, useRef } from "react";
import { Link, Route, Routes } from 'react-router-dom';
import * as s from "./styles/globalStyles";
import Home from "./components/Home";
import Mint from "./components/Mint";
import Ido from "./components/Ido"
import Nft from "./components/Nft";
import DogeRunner from "./components/DogeRunner";
import HomeBeforeLaunch from "./components/HomeBeforeLaunch";
import Stake from "./components/Stake";
import Unstake from "./components/Unstake";
import StakingCenter from "./components/StakingCenter";
import LaunchpadTemporary from "./components/LaunchpadTemporary";
import Launchpad from "./components/Launchpad";


import ComingSoon from "./components/ComingSoon";


function App() {
  return (
    <s.Screen>
      <Routes>
      {/* Temporarily disabled Home for PRE LAUNCH */}
        {/* <Route path="/" exact={true} element={<HomeBeforeLaunch/>}/> */}


      {/* REAL HOME - Uncomment for launch */}
        <Route path="/" exact={true} element={<Home/>}/>
        
        <Route path="/mint" exact={true} element={<Mint/>}/>
        <Route path="/mintxyz" exact={true} element={<Mint/>}/>

        <Route path="/nft" exact={true} element={<Nft/>}/>
        <Route path="/ido" exact={true} element={<Ido/>}/>
        <Route path="/doge2earn" exact={true} element={<DogeRunner/>}/>

        <Route path="/stake" exact={true} element={<Stake/>}/>
        <Route path="/unstake" exact={true} element={<Unstake/>}/>

        <Route path="/stakingc" exact={true} element={<StakingCenter/>}/>
        <Route path="/launchpad" exact={true} element={<Launchpad/>}/>

        <Route path="/comingsoon" exact={true} element={<ComingSoon/>}/>

        <Route path="*" element={<ComingSoon/>}/>
      </Routes>
    </s.Screen>
  );
}

export default App;