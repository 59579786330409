import React, { useState } from "react";

import Header2 from "./Header2";

import Stake from "./Stake.jsx";
import Unstake from "./Unstake.jsx";

const StakingCenter = () => {
  const [unstaking, setUnstaking] = useState(false);

  const changeToUnstake = () => {
    setUnstaking(!unstaking);
  }

  return (
    <div className="stake_main">
        <Header2/>
        <div className="stake_main_wrapper">
            <h2>STAKING CENTER</h2>
            <button style={{backgroundColor:"white", width:"70px", height:"30px"}} onClick={changeToUnstake}>
            {!unstaking ? 'Unstake' : 'Stake'}
            </button>
            {!unstaking ? <Stake/> : <Unstake/>}
        </div>

    </div>
  );
};

export default StakingCenter;
